@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply py-2.5 px-12 text-base font-normal text-white transition-all whitespace-nowrap duration-300;
}

.btn-enabled {
  @apply bg-[#83B2FA] hover:bg-lightblue-hover;
}

.btn:disabled {
  @apply bg-gray-400 cursor-not-allowed;
}
body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  box-sizing: border-box;
}

.indent {
  text-decoration: none;
  display: inline-block;
}

header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.modal {
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  background: #0000008c;
  height: 100%;
  max-width: 100%;
  padding-top: 30px;
  padding-right: 30px;
  z-index: 50;
  overflow: hidden;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

nav ul li a:hover {
  color: #007bff;
  transform: scale(1.1);
  font-weight: bold;
}

main {
  margin-top: 90px;
  width: 100vw;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mi {
  font-size: 20px;
  padding-right: 10px;
}

.title {
  padding-left: 60px;
}

.comparison {
  padding-top: 20px !important;
}

.billIdBox {
  margin-top: 90px !important;
  display: flex;
  flex-direction: column;
}

.billIdBox h1 {
  display: flex;
  width: 100%;
  place-content: center;
  margin-top: 0px;
}

.billIdBox button {
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
}

.QueryBtnBox {
  font-size: 10px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  place-content: center space-evenly;
}

.BillNum {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.logo {
  width: auto;
  height: 50px;
  margin-top: 10px;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.loadingSpinnerContainerQuery {
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BillBox {
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.section {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.section h3 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 20px;
}

.section p {
  color: #666;
  line-height: 1.6;
  display: none; /* Initially hide the paragraph content */
}

.section:hover {
  background-color: #f9f9f9;
}

.active p {
  display: block; /* Show content for active section */
}

.contentContainer {
  display: flex;
  flex-direction: row; /* Ensure content is laid out horizontally */
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: stretch; /* Stretch children to fill the container height */
}

.leftSide {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take up equal space as the other main section */
  max-width: 50%; /* Limit width to half of the container */
}

.versionSelector {
  display: flex;
  place-content: center space-evenly;
  margin-bottom: 20px; /* Space below the dropdown */
  padding: 0 20px; /* Padding on sides for spacing */
  width: 100%;
}

.vCompBtn {
  padding: 0.75rem 1.5rem;
  background-color: #0056b3 !important;
  margin: 0px !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.vCompBtn:hover {
  background-color: #003d82 !important;
}

.vCompBtn:disabled {
  background-color: #cccccc !important; /* Gray background for disabled state */
  cursor: not-allowed; /* Changes cursor to indicate the button is not clickable */
  color: #666666 !important; /* Dimmed text color for disabled state */
}

.versionSelector > div select {
  margin-left: 15px;
}

.versionSelectors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.versionSelectors select {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: #fff;
  cursor: pointer;
}

.versionToggle {
  display: flex;
  flex-flow: row nowrap;
  width: calc(50vw - 20px);
  place-content: center space-around;
  margin-left: 40px;
}

/*.versionSelector button {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 8px;
  margin: 0;
}*/

.versionSelector select {
  padding: 10px; /* Padding for aesthetics */
  border-radius: 5px; /* Rounded corners for the dropdown */
  border: 1px solid #ccc; /* Light border for definition */
}

.pdfViewer {
  flex: 1; /* Allow the PDF viewer to fill remaining space */
  overflow: hidden; /* Hide overflow */
  padding: 0 20px; /* Padding on sides for consistency */
}

/* Keep your existing .pdfViewer iframe styles */

.secSum {
  margin-top: 20px !important;
  margin-bottom: 0px;
}

.sectionSummaries {
  flex: 1 1 400px; /* Flex grow, flex shrink, and flex basis set to minimum width */
  overflow: auto; /* Makes each half scrollable independently */
  height: 100%; /* Ensures content uses the full container height */
  max-width: calc(50% - 20px); /* Adjust based on your needs, accounting for any margin/padding */
}

.pdfViewer iframe {
  border: none; /* Removes the border around the iframe */
  width: 100%; /* Ensures the iframe fills its container width */
  height: 100%; /* Ensures the iframe fills its container height */
  overflow: hidden;
}

.pdfViewer iframe::-webkit-scrollbar {
  display: none !important; /* Hide scrollbars in WebKit browsers */
}

.pdfViewer iframe {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

/* BillQueryForm Styles */
.billQueryForm {
  padding: 1rem;
  padding-top: 0px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.billQueryForm form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.billQueryForm label {
  flex-basis: 100%;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 1rem;
}

.billQueryForm input[type='text'] {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.billQueryForm select {
  margin-left: 10px;
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.billQueryForm button {
  padding: 0.75rem 1.5rem;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.billQueryForm button:hover {
  background-color: #003d82;
}

.good,
.bad {
  margin: 0px !important;
  padding: 0px !important;
}

.queryResponse {
  margin-top: 1rem;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 4px;
  width: calc(100% - 30px);
}

.queryResponse > div {
  border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.queryResponse h3 {
  margin-top: 0;
  color: #0056b3;
}

.queryResponse p {
  color: #666;
}

.blue {
  color: #007bff !important;
}

.red {
  color: red;
}

.responseBox {
  display: flex;
  align-items: flex-start; /* Align children to the top */
  width: calc(100% - 40px);
}

.queryPart,
.responsePart {
  display: flex;
  flex-direction: column;
}

.QueryBtnBox {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons in the box */
  gap: 5px; /* Adds space between buttons */
  width: 24px;
  margin-left: 10px !important;
}

.submitQuery {
  margin: 0px !important;
}

.strongMatch {
  margin-left: 5px;
}

/* Adjust button styles for better usability */
.QueryBtnBox button {
  font-size: 16px; /* Adjusted for visibility */
  padding: 5px; /* Adjust padding for a better look */
  width: auto; /* Allow the buttons to expand based on content */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  border: none; /* Remove border */
  border-radius: 4px; /* Add rounded corners */
  background: transparent;
}

.QueryBtnContent {
  display: flex;
  flex-direction: column;
  width: auto; /* Adjust based on your layout needs */
  width: 100%;
}

.responseBox {
  display: flex;
  flex-direction: column; /* Adjust if you need the box to lay out its children vertically */
}

.QueryBtnContent {
  display: flex;
  flex-direction: column;
}

.responseAndButtons {
  display: flex;
  align-items: flex-start; /* Align the response and buttons at the start */
}

.responsePart {
  flex-grow: 1; /* Allow the response part to take up the available space */
}

.QueryBtnBox {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons in the box */
  gap: 5px; /* Adds space between buttons */
}

.good,
.bad {
  padding: 4px !important;
}

.good:hover {
  background-color: #007bff !important;
}

.bad:hover {
  background-color: red !important;
}

.svg-icon {
  fill: #000000; /* Initial fill color */
  transition: fill 0.2s ease-in-out; /* Smooth transition for fill color */
}

.svg-icon:hover {
  fill: #ffffff; /* Fill color on hover */
}

.good.active {
  background-color: #007bff !important;
}

.good.active > svg {
  fill: #ffffff;
}

.bad.active {
  background-color: red !important;
}

.bad.active > svg {
  fill: #ffffff;
}

.insufficient {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
  background-color: white;
  color: #333;
  text-align: center;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 45px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  margin: auto;
}

.inputContainer {
  position: relative;
  top: 120px;
  width: 100%;
}

#inputField {
  position: relative;
  width: 600px;
  height: 35px;
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  margin-left: 0px;
}

.company-search {
  position: relative;
  width: 100%; /* Ensure the container can hold the dropdown width */
}

.dataResult {
  position: absolute;
  top: 100%; /* Positions the dropdown right below the input */
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 320px;
  overflow-y: auto;
}

.dataResult ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dataResult li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.dataResult li:hover {
  background-color: #f0f0f0;
}

.fixedHeader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; /* Ensure it stays on top */
}

.searchContainer {
  display: flex;
  place-content: center;
}

.scrollableContent {
  margin-top: 200px;
  flex: 1; /* Allow this section to expand */
  overflow-y: auto; /* Scrollable content */
  max-height: calc(100vh - 240px);
  width: 100%;
  margin-left: auto;
}

.ai-report-container {
  display: flex;
  place-content: center;
  height: 100%; /* Full viewport height */
  padding-left: 300px;
}

/* SideMenu.css */
.front {
  z-index: 1000;
}
.side-menu {
  position: fixed;
  top: 75px;
  left: 0;
  height: calc(100vh - 75px) !important;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition:
    width 0.3s ease,
    transform 0.3s ease;
  overflow: hidden;
  padding-bottom: 50px !important;
  z-index: 999;
}

.side-menu.closed {
  transform: translateX(-100%);
}

.toggle-button {
  position: absolute;
  top: 20px;
  right: -40px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.menu-content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.menu-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

[contenteditable='true']:focus {
  outline: none;
  border: none;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #555555;
}

.input-field,
.select-field,
.textarea-field {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-field:focus,
.select-field:focus,
.textarea-field:focus {
  border-color: #007bff;
  outline: none;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.checkbox-item input {
  margin-right: 10px;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-versions-btn {
  background-color: #28a745;
  color: #ffffff;
  margin-bottom: 15px;
}

.get-versions-btn:hover:not(:disabled) {
  background-color: #218838;
}

.compile-report-btn {
  background-color: #007bff;
  color: #ffffff;
}

.compile-report-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
  }
}

/* Scrollbar Styling */
.menu-content::-webkit-scrollbar {
  width: 8px;
}

.menu-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.menu-content::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

.menu-content::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

.no-tokens-message {
  max-width: 600px;
  margin: 50px auto;
  background: #fff3f3;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.no-tokens-message h2 {
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #d9534f;
}

.no-tokens-message p {
  font-size: 1.1em;
  line-height: 1.5em;
  color: #333;
}

.no-tokens-message .contact-link {
  color: #007bff;
  text-decoration: underline;
}

.no-tokens-message .contact-link:hover {
  color: #0056b3;
}

.report-contet {
  display: flex;
  place-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.report-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
}

.report-container {
  position: relative;
  margin: 20px auto; /* Centers the container horizontally and adds top and bottom margins */
  width: 100%;
  max-width: 1000px; /* Maximum width */
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  font-family: 'Times New Roman', Times, serif;
  box-sizing: border-box; /* Include padding and border in width */
}

.report-output {
  position: relative;
  z-index: 2; /* Ensure that text is above the watermark */
}

.report-output::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/reportLogo.png') center center;
  background-size: contain; /* or cover, depending on how you want the logo to appear */
  opacity: 0.2; /* Adjust opacity for desired watermark effect */
  pointer-events: none; /* Prevent interactions with the watermark */
  z-index: 1; /* Place it behind the text */
}

.print-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: rgba(40, 167, 69, 0.8);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.print-button:hover {
  background-color: rgba(40, 167, 69, 1);
  transform: translateY(-2px);
}

.print-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Report Sections */
.report-section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fdfdfd;
  border: 1px solid #ddd;
}

.report-section.full-width {
  width: 100%;
}

.report-section.flex-item {
  flex: 1;
  margin-right: 20px;
}

.report-section:last-child {
  margin-right: 0;
}

/* Grouped Sections */
.report-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.report-group .report-section {
  margin-bottom: 0;
}

/* Section Titles */
.section-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

/* Paragraphs */
.section-paragraph {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Lists */
.section-list {
  list-style-type: disc;
  margin-left: 20px;
}

.section-list li {
  margin-bottom: 10px;
}

/* Redo Button */
.RedoButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media print {
  /* Hide everything except #report-output */
  body * {
    visibility: hidden;
  }

  #report-output,
  #report-output * {
    visibility: visible;
  }

  /* Optionally adjust positioning to ensure it starts at the top of the page */
  #report-output {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.tableHeader {
  background-color: #007bff;
  color: white;
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.tableData {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.reportTable tr.evenRow {
  background-color: #f2f2f2;
}

/* List Styling with Class Names */
.lobbyList,
.companyList {
  list-style: none;
  padding: 0;
}

.lobbyItem,
.companyItem {
  padding: 5px 0;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 20px;
  font-size: 14px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  text-align: left !important;
  color: #000;
  text-decoration: none;
}

.dataItem p {
  margin-left: 10px;
}
.dataResult > a {
  text-decoration: none;
}

.dataResult > a:hover {
  background-color: lightgrey;
  color: #007bff;
}

.searchBar {
  width: 60%;
}

.clientsContainer {
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  width: 1100px;
}

.clientsTable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.clientsTable th,
.clientsTable td {
  padding: 12px 15px;
  border-bottom: 1px solid #eaeaea;
}

.clientsTable th {
  background-color: #f5f5f5;
  cursor: pointer;
}

.clientsTable th:hover {
  background-color: #f0f0f0;
}

.lobbyCompanyName {
  color: #333;
  font-weight: bold;
}

.lobbyCompanyName {
  text-decoration: none;
  color: inherit;
}

tr.classT {
  background-color: #ffe8e8; /* Light red for terminated clients */
}

.companyDetails {
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  margin: 0 !important;
}

.companyDetails p {
  margin-left: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.lobbyistDetails {
  display: flex;
  align-items: start;
  gap: 20px;
}

.lobbyistImage {
  margin: auto;
  width: 250px;
  max-height: 200px;
  object-fit: contain;
  margin-left: 40px;
}

.lobbyistInfo {
  flex-grow: 1;
}

.lobbyistInfo h1 {
  margin-left: -75px;
}

.printBox {
  display: flex;
  align-items: center; /* Align items vertically */
  place-content: space-between;
  gap: 10px; /* Add some space between the title and the button */
  background-color: #f8f9fa;
}

.printBox h3 {
  margin: 0; /* Remove default margin */
  color: #007bff; /* A shade of blue */
  font-size: 20px; /* Slightly larger */
}

.printBox button {
  margin: 0px;
  margin-left: 5px;
  padding: 2px 10px; /* Adequate padding for clickability */
  height: auto;
  width: auto;
  font-size: 14px; /* Adjusted for discreteness */
  background-color: #f0f0f0; /* Light background */
  color: #007bff; /* Matching the h3 color */
  border: 1px solid #007bff; /* Solid border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor on hover */
  transition:
    background-color 0.3s ease,
    color 0.3s ease; /* Smooth transition for hover effect */
}

.printBox button:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #ffffff; /* White text on hover */
}

.printBox button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: none;
}

.printBox button:disabled:hover {
  background-color: #cccccc; /* Prevents color change on hover */
}

.lobbyName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff; /* Adjust the color as needed */
}

/* Example of additional styles 
.lobbyName::first-letter {
  font-size: 32px;
  color: #007bff; 
}*/

.lobbyName em {
  font-style: italic;
  color: #666; /* Adjust the color as needed */
}

.emailModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.emailModal form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9 !important;
  width: 600px;
}

.xOut {
  position: absolute;
  margin: 0px;
  padding: 0px;
  display: flex;
  place-content: center;
  align-items: center;
  top: 30px;
  left: 30px;
  height: 30px;
  width: 30px;
}

.emailBtn {
  background-color: none;
  margin: auto 10px;
  width: 140px;
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;
}

.emailHead {
  display: flex;
  flex-flow: row wrap;
  place-content: space-between;
  width: calc(100% - 60px);
}

.emailHead div {
  margin: auto;
}

.searchStyle {
  width: 100%;
  margin: 110px auto 30px auto;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
}

.inputStyle {
  padding: 5px;
  width: 500px;
  border-radius: 10px;
  border-color: #ddd;
}

.optionsStyle {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.labelStyle {
  margin-right: 10px;
  margin-left: 10px;
}

.resultStyle {
  margin-bottom: 10px;
}

.tableStyle {
  width: 80%;
  border-collapse: collapse;
  margin: 0 auto;
  table-layout: fixed;
}

.headerTdStyle,
.headerTrackStyle,
.headerTitleTdStyle {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 8px;
  word-break: break-word;
}

.headerTrackStyle {
  width: 45px;
}

.headerTitleTdStyle {
  width: calc(80vw - 296px);
}

.modeSelector {
  display: flex;
  flex-flow: row nowrap;
}
.modeButton {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  margin: 0px !important;
}

.modeButton.selected {
  font-weight: bold;
  background-color: #83b2fa;
  color: white;
  border-color: #83b2fa;
}

.recommendedQueries {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  place-content: center space-between; /* Center items and distribute space between */
  background-color: #f8f9fa; /* Barely noticeable background, adjust as needed */
  padding: 10px 0; /* Add some vertical padding */
  border-radius: 5px; /* Optional: adds rounded corners */
}

.recommendedQueries ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
}

.recommendedQueries li {
  cursor: pointer;
  color: #007bff;
  padding: 5px 10px; /* Adjust padding to control the size */
  border: 1px solid #b8daff; /* Softer blue, adjust as needed */
  border-radius: 20px;
  font-weight: bold;
  flex: 1; /* Makes each item take equal space */
  text-align: center; /* Ensures text is centered */
  margin: 0 5px; /* Adds a little space between items */
}

/* Optional: Add a hover effect for better interactivity */
.recommendedQueries li:hover {
  background-color: #e2e6ea; /* Slightly darker background on hover */
}

.notification {
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.noQuery {
  font-size: 20px;
  margin-left: 15px;
}

.billComparisons {
  display: flex;
  justify-content: space-between;
}

.billVersion {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: calc(50vw - 20px);
}

.billVersion pre {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow: auto;
}

.billVersion h3 {
  display: flex;
  align-items: flex-start !important;
}

.removed {
  background-color: #ffe8e8; /* Soft red for removed text */
}

.added {
  background-color: #e8f4ff; /* Soft blue for added text */
}

.changesSummary {
  margin-top: 20px;
}

.preformatted-text {
  white-space: pre-wrap; /* or 'pre' if you want to preserve both spaces and line breaks */
  width: auto;
}

.compareBox {
  display: flex;
  flex-flow: row nowrap;
}

.sumChange {
  width: 100%;
  text-align: center;
}

.sideBySideContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.billVersion {
  flex: 1;
  /* Add padding or margins as needed */
}

.sideBySideSummaries {
  display: none;
}

.contactMeContainer {
  max-width: 1000px;
  min-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.contactForm label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.contactForm input,
.contactForm textarea {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contactForm textarea {
  height: 150px;
}

.contactForm button {
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contactForm button:hover {
  background-color: #0056b3;
}

.company-search {
  max-width: 600px;
  margin: 0 auto;
}

.bodyBox {
  margin-top: 130px;
}

.reportBox {
  margin-top: 0px;
}

.aiinput {
  width: calc(100% - 24px);
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.company-info {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.company-info h3 {
  margin: 0 0 10px;
}

.company-info p {
  margin: 5px 0;
}

@media (max-width: 900px) {
  .searchStyle {
    margin-top: 200px;
  }
}

/* Responsive adjustments */
@media (max-width: 800px) {
  .contentContainer {
    flex-direction: column; /* Stack the containers on smaller screens */
  }

  .pdfViewer,
  .sectionSummaries {
    /* Ensure full width in stacked layout, adjust as necessary for padding/margin */
    max-width: 100%;
    flex-basis: auto; /* Adjust basis to auto for vertical stacking */
  }

  .searchStyle {
    margin-top: 200px;
  }

  .menu-items {
    /* Adjust styles to display the menu items vertically below the logo */
    flex-direction: row;
    align-items: center; /* Center menu items if you want */
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulseAnimation 2s infinite;
}

.AIReportBox {
  display: flex !important;
  flex-flow: row nowrap !important;
  place-content: center space-between;
  max-width: 1300px;
  margin: auto;
  background-color: transparent;
  box-shadow: none;
}

.AIReportBox div {
  margin: 0px;
  padding: 0px;
}

.AIReportBox input {
  margin-left: 10px;
}

.AIReportSection {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.full-sum-input {
  margin-bottom: 20px;
  max-width: 100vw; /* Align with .company-search for consistency */
  margin: 20px auto; /* Center the text area horizontally */
}

.full-sum-input textarea {
  width: 100%;
  padding: 12px 14px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  resize: vertical; /* Allow vertical resizing only */
  box-sizing: border-box;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Consistent font */
}

.full-sum-input textarea:focus {
  border-color: #007bff; /* Primary color on focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Subtle shadow for focus state */
  outline: none; /* Remove default outline */
}

.full-sum-input label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #333; /* Darker text for better readability */
}

.flex-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flex-item {
  flex: 1;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.full-width {
  width: 100%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.section-company-sentiment,
.section-summary {
  background-color: #f4f4f4;
}

.section-effective-date,
.section-area-of-effect,
.section-who-benefits,
.section-who-is-hurt {
  background-color: #fff;
}

.responseBody {
  max-width: 1300px;
  margin: auto;
}

.tabs-container {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}

.tab-content > div {
  display: block;
}

.tabs-nav {
  margin: 0;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  list-style: none;

  overflow: hidden;
  padding: 6px;
  border: 1px solid #e5e7eb;
}

.tabs-nav li {
  display: flex;
  vertical-align: top;
  width: 100%;
  text-align: center;
}

.tabs-nav div {
  color: #000;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 8px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.tabs-nav li.active div {
  color: #fff;
  background: #83b2fa;
}

.tab-content {
  width: 100%;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: #fff;
  overflow: hidden;
}

/* Vote Table */
/* styles.css */

/* Vote Table Styles */
/* Vote Table Styles */
.filters {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.filters label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.vote-table-container {
  padding: 20px;
}

.vote-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.vote-table th,
.vote-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.vote-table th {
  background-color: #f8f8f8;
  cursor: pointer;
  position: sticky;
  top: 0;
}

.vote-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.vote-table tr:hover {
  background-color: #f1f1f1;
}

/* Bar Container in Table */
.bar-container {
  display: flex;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #e0e0e0;
}

.bar {
  height: 100%;
}

.yes-bar {
  background-color: #4caf50;
}

.no-bar {
  background-color: #f44336;
}

.bar-label {
  color: #fff;
  font-weight: bold;
  padding: 0 5px;
}

.yes-bar .bar-label {
  justify-content: flex-start;
}

.no-bar .bar-label {
  justify-content: flex-end;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.modal-content {
  font-family: 'Lexend Deca', sans-serif;
}

.modal-content button,
.modal-content .modal-bar-container,
.modal-content .vote-explanation,
.modal-content p,
.modal-content ul,
.modal-content li {
  font-family: 'Lexend Deca', sans-serif;
}

.modal-bar-container {
  display: flex;
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}

.vote-explanation {
  white-space: pre-wrap;
  font-family: monospace;
  overflow-wrap: break-word; /* Ensures text wraps within container */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 600px) {
  .modal-content {
    max-width: 95%;
    max-height: 95%;
  }
}
