.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.dropdown {
  position: relative;
}

.dropbtn {
  border-radius: 10px;
  border: var(--Spacing-System-Spacing-1px, 1px) solid transparent;
}

.dropbtn .arrow-svg {
  transition: all 0.6s ease-out;
}

.dropbtn.active .arrow-svg {
  transform: rotate(180deg);
}

.dropbtn.active,
.dropbtn:hover {
  border: var(--Spacing-System-Spacing-1px, 1px) solid rgba(255, 255, 255, 0.2);
  background: rgba(251, 250, 248, 0.05);
  box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.05);
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 12px 16px;
  border-radius: 12px;
  top: 32px;
}

/* #bill-pdf {
  width: 100%;
  height: 539px;
} */

/* input[type="checkbox"]:checked {
  background-image: url(assets/images/tick.svg);
} */

#chart-column {
  max-width: 760px;
  transform: translateX(-10px);
}

#donut-chart {
  transform: translateX(-30px);
}
